import * as React from "react";
import { useTranslation } from "..";

export const Translate = React.memo

(function Translate({ t: translationIdentifier }) {
  const t = useTranslation();

  // TODO:
  // @ts-ignore
  return t(translationIdentifier) ;
});
