

export class StorageAdapterContext {
  
  

  constructor(storage, service) {
    this.storage = storage;
    this.service = service;
  }

  setLoading(value) {
    this.service.setLoading(value);
  }

  set(key, value) {
    this.storage.set(key, value);
    this.service._clearCache(key);
    this.service.notifySubscribers();
  }

  delete(key) {
    this.storage.delete(key);
    this.service.notifySubscribers();
  }

  clear() {
    this.storage.clear();
    this.service.notifySubscribers();
  }
}
