const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\FormatRelativeDates.tsx";import * as React from "react";

import { formatRelativeDates, useInterval } from "..";





export const FormatRelativeDates = React.memo(({ start }) => {
  const [state, setState] = React.useState(formatRelativeDates(start));

  useInterval(() => {
    const next = formatRelativeDates(start);

    if (next !== state) {
      setState(next);
    }
  }, 500);

  return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}, state);
});
