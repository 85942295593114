const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\Suspense.tsx";import * as React from "react";
import { Loading } from "..";





export const Suspense = ({ children }) => {
  return (
    React.createElement(React.Suspense, { fallback: React.createElement(Loading, { message: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}} ), children: children, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}} )
  );
};
