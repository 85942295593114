const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\Boundary.tsx";import * as React from "react";

import { ErrorBoundary, Suspense } from "..";

export const Boundary = React.memo(
  function Boundary({ children, ...props }) {
    return (
      React.createElement(ErrorBoundary, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
        , React.createElement(Suspense, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}, children)
      )
    );
  }
);
