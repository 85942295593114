const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\NavigationMenu.tsx";import { Icon } from "@opendash/icons";
import { Menu } from "antd";

import { observer } from "mobx-react-lite";
import * as React from "react";
import { $framework, useNavigation, useTranslation } from "..";



export const NavigationMenu = observer(function NavigationMenu(props) {
  const t = useTranslation();
  const [groups, items, activeItems] = useNavigation("sidebar");

  return (
    React.createElement(Menu, {
      mode: "inline",
      selectedKeys: activeItems,
      defaultOpenKeys: groups.map((group) => group.id),
      ...props,
      items: items
        .filter((item) => item.group === null)
        .map((items) => {
          return {
            key: items.id,
            label: !items.icon ? (
              t(items.label)
            ) : (
              React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
                , items.icon && React.createElement(Icon, { icon: items.icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} ), " " , t(items.label)
              )
            ),
            onClick: () => {
              if (items.link && typeof items.link === "string") {
                $framework.router.navigate(items.link );
              } else {
                (items.link )();
              }
            },
          };
        })
        .concat(
          groups.map((group) => {
            return {
              key: group.id,
              label: !group.icon ? (
                t(group.label)
              ) : (
                React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
                  , group.icon && React.createElement(Icon, { icon: group.icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} ), " " , t(group.label)
                )
              ),
              onClick: () => {},
              children: group.children.map((route) => {
                return {
                  key: route.id,
                  label: t(route.label),
                  icon: route.icon ? React.createElement(Icon, { icon: route.icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} ) : undefined,
                  onClick: () => {
                    if (route.link && typeof route.link === "string") {
                      $framework.router.navigate(route.link );
                    } else {
                      (route.link )();
                    }
                  },
                };
              }),
            };
          })
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
    )
  );
});
