const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\_provider\\CommandBar\\CommandBarResults.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { KBarResults, useMatches } from "kbar";
import { observer } from "mobx-react-lite";
import * as React from "react";

export const CommandBarResults = observer(function CommandBarResults() {
  const { results, rootActionId } = useMatches();

  return (
    React.createElement(KBarResults, {
      items: results,
      onRender: ({ item, active }) =>
        typeof item === "string" ? (
          React.createElement('div', { className: "opendash-commandbar--group", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, item)
        ) : (
          React.createElement(CommandBarResult, {
            action: item,
            active: active,
            currentRootActionId: rootActionId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
          )
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    )
  );
});

const CommandBarResult = observer



(function CommandBarResult({ action, active, currentRootActionId }) {
  const ancestors = React.useMemo(() => {
    if (!currentRootActionId) return action.ancestors;
    const index = action.ancestors.findIndex(
      (ancestor) => ancestor.id === currentRootActionId
    );
    return action.ancestors.slice(index + 1);
  }, [action.ancestors, currentRootActionId]);

  return (
    React.createElement('div', {
      className: 
        active
          ? "opendash-commandbar--result opendash-commandbar--result--active"
          : "opendash-commandbar--result"
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}

      , React.createElement('div', { className: "opendash-commandbar--result-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        , action.icon && React.createElement(Icon, { icon: action.icon , __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )

        , React.createElement('div', { className: "opendash-commandbar--result-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          , React.createElement('div', { className: "opendash-commandbar--result-parents-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
            , _optionalChain([ancestors, 'optionalAccess', _ => _.length]) > 0 &&
              ancestors.map((ancestor) => (
                React.createElement(React.Fragment, { key: ancestor.id, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
                  , React.createElement('span', { className: "opendash-commandbar--result-parents-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
                    , ancestor.name
                  )
                  , React.createElement('span', { className: "opendash-commandbar--result-parents-divider", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "›"

                  )
                )
              ))
            , React.createElement('span', { className: "opendash-commandbar--result-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
              , action.name
            )
          )
          , action.subtitle && (
            React.createElement('span', { className: "opendash-commandbar--result-description", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
              , action.subtitle
            )
          )
        )
      )
      , _optionalChain([action, 'access', _2 => _2.shortcut, 'optionalAccess', _3 => _3.length]) ? (
        React.createElement('div', { 'aria-hidden': true, className: "opendash-commandbar--result-keys", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          , action.shortcut.map((sc) => (
            React.createElement('kbd', { key: sc, className: "opendash-commandbar--result-key", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
              , sc
            )
          ))
        )
      ) : null
    )
  );
});
