import picomatch from "picomatch";
import * as React from "react";
import {



  useAppState,
  useOpenDashApp,
  usePath,
  usePermissions,
} from "..";

export function useNavigation(
  place
)



 {
  const app = useOpenDashApp();
  const path = usePath();
  const permissions = usePermissions();

  const [userGroups, userItems] = useAppState((state) => [
    state.navigation.userNavigationGroups,
    state.navigation.userNavigationItems,
  ]);

  const groups = React.useMemo(
    () =>
      [...userGroups, ...app.ui.staticNavigationGroups].sort(
        (a, b) => a.order - b.order
      ),
    [userGroups]
  );

  const items = React.useMemo(
    () =>
      [...userItems, ...app.ui.staticNavigationItems]
        .filter((item) => item.place === place)
        .filter(
          (item) =>
            !item.permission ||
            (Array.isArray(item.permission)
              ? item.permission.every((p) => permissions.includes(p))
              : permissions.includes(item.permission))
        )
        .filter((item) =>
          item.routeCondition
            ? picomatch.isMatch(path, item.routeCondition)
            : true
        )
        .sort((a, b) => a.order - b.order),
    [userItems, path, place, permissions]
  );

  const activeItems = React.useMemo(() => {
    return items
      .filter((item) =>
        item.activeCondition
          ? picomatch.isMatch(path, item.activeCondition)
          : false
      )
      .map((item) => item.id);
  }, [items, path]);

  const result = React.useMemo(() => {
    return groups
      .map((group) => {
        return {
          ...group,
          children: items.filter((item) => item.group === group.id),
        };
      })
      .filter((group) => group.children.length > 0);
  }, [groups, items]);

  return [result, items, activeItems];
}
