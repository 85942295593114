const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\RouterRootHandler.tsx";import { Outlet } from "@opendash/router";
import * as React from "react";
import { Boundary, OpenDashGlobals } from "..";
import Layout from "./_layout";

export const RouterRootHandler = React.memo(function RouterRootHandler() {
  return (
    React.createElement(React.Fragment, null
      , React.createElement(OpenDashGlobals, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 9}} )
      , React.createElement(Boundary, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
        , React.createElement(Layout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
          , React.createElement(Boundary, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
            , React.createElement(Outlet, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 13}} )
          )
        )
      )
    )
  );
});
