const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\OpenDashProvider.tsx";import * as React from "react";

import { AntDesignProvider, OpenDashContext } from "..";





const NestedProviders = React.memo(
  function NestedProviders({ providers, children }) {
    if (providers.length === 0) {
      return React.createElement(React.Fragment, null, children);
    }

    const [Provider, props] = providers[0];

    return (
      React.createElement(Provider, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , React.createElement(NestedProviders, { providers: providers.slice(1), children: children, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )
      )
    );
  }
);

export function OpenDashProvider({ app, children }) {
  const providers = app._internal.providerComponents;

  return (
    React.createElement(OpenDashContext.Provider, { value: app, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , React.createElement(AntDesignProvider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        , React.createElement(NestedProviders, { providers: providers, children: children, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}} )
      )
    )
  );
}
