const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\AppSidebar.tsx";import { Drawer } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import { LinkedSourcePicker, NavigationMenu, useOpenDashApp } from "..";

export const Container = styled.div``;

const ContentContainer = styled.div``;

const SourcePickerContainer = styled.div`
  padding: 16px;
`;





export const AppSidebar = observer(function AppSidebar({
  children,
  showSourcePicker,
}) {
  const app = useOpenDashApp();

  React.useEffect(() => {
    app.ui2.updateSidebar({ available: true });

    return () => {
      app.ui2.updateSidebar({
        open: false,
        available: false,
      });
    };
  }, []);

  return (
    React.createElement(Drawer, {
      open: app.ui2.sidebar.open,
      onClose: () => app.ui2.updateSidebar({ open: false }),
      closable: false,
      placement: "left",
      rootStyle: {
        position: "fixed",
        zIndex: 998,
        top: "var(--opendash-header-height)",
        height: "calc(100vh - var(--opendash-header-height))",
      },
      styles: { body: { padding: 0 } },
      width: 500,
      autoFocus: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}

      , React.createElement(Container, {
        onClick: () => {
          app.ui2.updateSidebar({ open: false });
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}

        , showSourcePicker && (
          React.createElement(SourcePickerContainer, { onClick: (e) => e.stopPropagation(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
            , React.createElement(LinkedSourcePicker, { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} )
          )
        )

        , React.createElement(ContentContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, children)

        , React.createElement(NavigationMenu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
      )
    )
  );
});
