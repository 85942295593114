import { App } from "antd";

/**
 * Used as central point to create notifications and messages.
 * @returns the objects to create notifications and messages with antd
 */
export function useFeedback() {
  const { notification, message } = App.useApp();

  return { notification, message };
}
