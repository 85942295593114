const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\OpenDashApp.tsx";import { RouterProvider } from "@opendash/router/dist/internal";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { $framework, Boundary, Loading, OpenDashProvider } from "..";

const css = `
  .ant-table-wrapper {
    overflow: auto;
  }
`;

export function OpenDashApp({ app }) {
  return (
    React.createElement(Boundary, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , React.createElement(OpenDashProvider, { app: app, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        , React.createElement(OpenDashRouter, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
      )
      , React.createElement('style', { dangerouslySetInnerHTML: { __html: css }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} )
    )
  );
}

const OpenDashRouter = observer(function OpenDashRouter() {
  if ($framework.ui2.servicesLoading) {
    return React.createElement(Loading, { message: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} );
  }

  return React.createElement(RouterProvider, { router: $framework.router.__getRoutes(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} );
});
