import styled from "styled-components";

export const Header = styled.header`
  background: white;
  border-bottom: 2px solid var(--ant-primary-color);
  padding: 0px;

  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
`;

export const HeaderRow = styled.div`
  line-height: var(--opendash-header-row-height);
  height: var(--opendash-header-row-height);
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
    height: calc(var(--opendash-header-row-height) + 1px);
  }
`;

// export const HeaderDropdown = styled.div<{ right: boolean }>`
//   display: block;
//   padding: 1px;
//   background: #005493;
//   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
//   top: -6px;
//   position: relative;
//   min-width: 302px;
//   /* margin-left: -10px; */

//   ::before {
//     width: 0;
//     height: 0;
//     border-left: 5px solid transparent;
//     border-right: 5px solid transparent;
//     border-bottom: 5px solid #005493;
//     content: "";
//     position: absolute;
//     top: -5px;
//     left: ${(p) => (p.right ? "auto" : "18px")};
//     right: ${(p) => (p.right ? "18px" : "auto")};
//   }
// `;

// export const HeaderDropdownText = styled.div`
//   padding: 10px;
//   color: #fff;
// `;

// export const HeaderDropdownButton = styled.button`
//   color: #fff;
//   background: #0063ac;
//   display: block;
//   line-height: 40px;
//   min-width: 300px;
//   padding: 0 10px;
//   border: 0;
//   border-bottom: 1px solid #005493;
//   text-align: left;

//   :first {
//     border-top: 1px solid #005493;
//   }

//   :hover {
//     color: #fff;
//     background: #005493;
//   }

//   .anticon {
//     margin-right: 10px;
//   }
// `;

export const Layout = styled.section`
  display: flex;
  flex: auto;
  flex-direction: column;
  background: #f0f2f5;

  width: 100%;
  min-height: 100vh;
  padding-top: var(--opendash-header-height);
`;

export const Content = styled.main`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: auto;
  min-height: 0;
`;

export const Footer = styled.footer`
  flex: 0 0 auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0px;
`;

export const FooterRowCopyright = styled.div`
  text-align: center;

  height: 40px;
  line-height: 40px;

  > *,
  > a {
    font-size: 12px;
    color: darkgrey;
  }
`;
