const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\MarkerCanvas.tsx";import * as React from "react";

import { useEventListener, uuid } from "..";


































export const MarkerCanvas = React.memo(function MarkerCanvas({
  edit,
  ...props
}) {
  if (edit) {
    return React.createElement(ImageEdit, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}} );
  }

  return React.createElement(ImageRender, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} );
});

function ImageEdit({
  width,
  height,
  imageWidth,
  imageHeight,
  imageUrl,
  markers,
  onCreateMarker: createMarker,
  onUpdateMarkerPosition: updateMarker,
  onMarkerDoubleClick,
}) {
  const mouseposition = React.useRef([0, 0]);
  const annotationDrag = React.useRef(null);

  const imageContainerRef = React.useRef(null);
  const scale = width / imageWidth;

  const calculatePosition = () => {
    if (!imageContainerRef.current) {
      return [0, 0];
    }

    const [mouseX, mouseY] = mouseposition.current;
    const maxX = imageWidth;
    const maxY = imageHeight;

    const viewportOffset = imageContainerRef.current.getBoundingClientRect();

    const offsetTop = viewportOffset.top;
    const offsetLeft = viewportOffset.left;

    let x = (mouseX - offsetLeft - 15) / scale;
    let y = (mouseY - offsetTop - 15) / scale;

    x = Math.round(x / 10) * 10;
    y = Math.round(y / 10) * 10;

    x = Math.round(Math.max(0, Math.min(x, maxX)));
    y = Math.round(Math.max(0, Math.min(y, maxY)));

    return [x, y];
  };

  useEventListener("mousemove", (e) => {
    mouseposition.current = [e.clientX, e.clientY];

    if (annotationDrag.current !== null) {
      // drag
      const [x, y] = calculatePosition();

      const left = Math.round(x * scale);
      const top = Math.round(y * scale);

      const [, element] = annotationDrag.current;

      element.style.top = top + "px";
      element.style.left = left + "px";
    }
  });

  useEventListener("mouseup", (e) => {
    if (annotationDrag.current !== null) {
      const [x, y] = calculatePosition();
      const [index] = annotationDrag.current;

      updateMarker(index, x, y);
    }

    annotationDrag.current = null;
  });

  return (
    React.createElement('div', {
      ref: imageContainerRef,
      style: {
        width,
        height,
        position: "relative",
        userSelect: "none",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}

      , React.createElement('img', {
        src: imageUrl,
        style: {
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          width,
          height: Math.round(imageHeight * scale),
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
      )
      , React.createElement('div', {
        style: {
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 2,
          width,
          height,
        },
        onClick: (e) => {
          const key = uuid();
          const [x, y] = calculatePosition();

          createMarker(key, x, y);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
      )
      , Object.entries(markers).map(([key, marker]) =>
        marker.render({
          key: key,
          style: {
            position: "absolute",
            left: Math.round(marker.x * scale) + "px",
            top: Math.round(marker.y * scale) + "px",
            zIndex: 3,
          },

          onClick: (e) => {
            if (e.detail === 2) {
              // on dubble klick
              onMarkerDoubleClick(key);
            }
          },
          onMouseDown: (e) => {
            // drag start
            annotationDrag.current = [key, e.target ];
          },
        })
      )
    )
  );
}

function ImageRender({
  width,
  height,
  imageWidth,
  imageHeight,
  imageUrl,
  markers,
}) {
  const scale = width / imageWidth;

  return (
    React.createElement('div', {
      style: {
        width,
        height,
        position: "relative",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 194}}

      , React.createElement('img', {
        src: imageUrl,
        style: {
          width,
          height: Math.round(imageHeight * scale),
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}
      )
      , Object.entries(markers).map(([key, marker]) =>
        marker.render({
          key: key,
          style: {
            position: "absolute",
            left: Math.round(marker.x * scale) + "px",
            top: Math.round(marker.y * scale) + "px",
          },
        })
      )
    )
  );
}
