const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\OpenDashLogo.tsx";import { useLocation, useNavigate } from "@opendash/router";
import * as React from "react";
import { useOpenDashApp } from "..";

export const OpenDashLogo

 = ({ style = {} }) => {
  const app = useOpenDashApp();
  const navigate = useNavigate();

  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  const onClick = React.useCallback(() => {
    if (app.ui.logoLink && app.ui.logoLinkExternal) {
      window.location.href = app.ui.logoLink;
    } else if (app.ui.logoLink && !app.ui.logoLinkExternal) {
      navigate(app.ui.logoLink);
    } else {
      navigate("/");
    }
  }, []);

  const pathname = usePathname();
  const isMobile = window.matchMedia("(max-width: 500px)").matches;

  if (app.ui.logoImage || app.ui.logoText) {
    return (
      React.createElement('svg', {
        x: "0px",
        y: "0px",
        xmlns: "http://www.w3.org/2000/svg",
        xmlSpace: "preserve",
        style: {
          padding: "7px",
          fillRule: "evenodd",
          clipRule: "evenodd",
          strokeMiterlimit: 1,
          width: isMobile ? app.ui.logoMobileSize + "px" : "275px",
          height: "100%",
          cursor: "hand",
        },
        onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}

        , React.createElement('image', {
          height: "100%",
          preserveAspectRatio: "none",
          href: app.ui.logoImage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
        )

        , app.ui.logoText && React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, app.ui.logoText)
      )
    );
  }

  return (
    React.createElement(React.Fragment, null
      , pathname.includes("/bde/") ? (
        React.createElement('svg', {
          x: "0px",
          y: "0px",
          viewBox: "2000 0 232 576"   ,
          xmlns: "http://www.w3.org/2000/svg",
          xmlSpace: "preserve",
          style: {
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeMiterlimit: 1,
            width: "250px",
            height: "70%",
            cursor: "hand",
          },
          onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}

          , React.createElement('text', {
            x: 332.156,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#666",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}

            , "o"
            , React.createElement('tspan', {
              x: "447.691px 566.474px 669.749px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}

              , "pen"
            )
          )
          , React.createElement('text', {
            x: 854.111,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#d2d532",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}

            , "CHECK"
            , React.createElement('tspan', {
              x: "1008.57px 1140.34px 1239.63px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}

              , ""
            )
          )
          , React.createElement('path', {
            d: "M0 122.953-37.645 10.171C-7.187 0 24.409 2.23 53.131 16.578L0 122.953Z"        ,
            style: {
              fill: "#b2b2b2",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 37.969)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
          )
          , React.createElement('path', {
            d: "m0 112.782-105.425-54.979C-90.577 29.33-68.103 10.169-37.645 0L0 112.782Z"     ,
            style: {
              fill: "#b2b2b2",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 58.311)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
          )
          , React.createElement('path', {
            d: "m0 21.292-114.03 33.687c-9.094-30.794-6.248-60.194 8.605-88.666L0 21.292Z"    ,
            style: {
              fill: "#b2b2b2",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 241.29)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
          )
          , React.createElement('path', {
            d: "M0-103.445-58.623 0c-27.936-15.838-46.307-38.961-55.407-69.758L0-103.445Z" ,
            style: {
              fill: "#d2d532",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 490.764)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
          )
          , React.createElement('path', {
            d: "m0-29.339 53.131-106.375c58.749 29.349 82.582 100.759 53.24 159.5C77.027 82.536 5.618 106.375-53.125 77.032c-2.356-1.173-3.21-1.633-5.498-2.926L0-29.339Z"          ,
            style: {
              fill: "#b2b2b2",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 342.552)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
          )
          , React.createElement('path', {
            d: "M-59.585-36.244c-37.049-.004-67.089 30.04-67.094 67.09.003 37.055 30.044 67.093 67.094 67.099 37.056-.007 67.098-30.043 67.098-67.095-.005-37.056-30.037-67.097-67.098-67.094"          ,
            style: {
              fill: "#fff",
              fillRule: "nonzero",
            },
            transform: "scale(2 -2) rotate(62.633 205.105 143.093)"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
          )
          , React.createElement('path', {
            style: {
              fill: "#d2d532",
            },
            d: "M808.838 77.111h31.59v31.597h-31.59z" ,
            transform: "matrix(-2 0 0 2 3298.54 204.362)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
          )
        )
      ) : pathname.includes("/monitoring/") ||
        pathname.includes("/openware/") ? (
        React.createElement('svg', {
          x: "0px",
          y: "0px",
          viewBox: "2000 0 232 576"   ,
          xmlns: "http://www.w3.org/2000/svg",
          xmlSpace: "preserve",
          style: {
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeMiterlimit: 1,
            width: "250px",
            height: "70%",
            cursor: "hand",
          },
          onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}

          , React.createElement('text', {
            x: 332.156,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#666",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 199}}

            , "o"
            , React.createElement('tspan', {
              x: "447.691px 566.474px 669.749px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 211}}

              , "pen"
            )
          )
          , React.createElement('text', {
            x: 854.111,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#4186c6",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 218}}

            , "DASH"
            , React.createElement('tspan', {
              x: "1008.57px 1140.34px 1239.63px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 230}}

              , ""
            )
          )
          , React.createElement('path', {
            d: "M0 122.953-37.645 10.171C-7.187 0 24.409 2.23 53.131 16.578L0 122.953Z"        ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 37.969)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 237}}
          )
          , React.createElement('path', {
            d: "m0 112.782-105.425-54.979C-90.577 29.33-68.103 10.169-37.645 0L0 112.782Z"     ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 58.311)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}
          )
          , React.createElement('path', {
            d: "m0 21.292-114.03 33.687c-9.094-30.794-6.248-60.194 8.605-88.666L0 21.292Z"    ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 241.29)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 257}}
          )
          , React.createElement('path', {
            d: "M0-103.445-58.623 0c-27.936-15.838-46.307-38.961-55.407-69.758L0-103.445Z" ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 490.764)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 267}}
          )
          , React.createElement('path', {
            d: "m0-29.339 53.131-106.375c58.749 29.349 82.582 100.759 53.24 159.5C77.027 82.536 5.618 106.375-53.125 77.032c-2.356-1.173-3.21-1.633-5.498-2.926L0-29.339Z"          ,
            style: {
              fill: "#4186c6",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 342.552)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 277}}
          )
          , React.createElement('path', {
            d: "M-59.585-36.244c-37.049-.004-67.089 30.04-67.094 67.09.003 37.055 30.044 67.093 67.094 67.099 37.056-.007 67.098-30.043 67.098-67.095-.005-37.056-30.037-67.097-67.098-67.094"          ,
            style: {
              fill: "#fff",
              fillRule: "nonzero",
            },
            transform: "scale(2 -2) rotate(62.633 205.105 143.093)"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 287}}
          )
          , React.createElement('path', {
            style: {
              fill: "#4186c6",
            },
            d: "M808.838 77.111h31.59v31.597h-31.59z" ,
            transform: "matrix(-2 0 0 2 3298.54 204.362)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 295}}
          )
        )
      ) : pathname.includes("/admin/") || pathname.includes("/feedback") ? (
        React.createElement('svg', {
          x: "0px",
          y: "0px",
          viewBox: "2000 0 232 576"   ,
          xmlns: "http://www.w3.org/2000/svg",
          xmlSpace: "preserve",
          style: {
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeMiterlimit: 1,
            width: "250px",
            height: "70%",
            cursor: "hand",
          },
          onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 304}}

          , React.createElement('text', {
            x: 332.156,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#666",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 320}}

            , "o"
            , React.createElement('tspan', {
              x: "447.691px 566.474px 669.749px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 332}}

              , "pen"
            )
          )
          , React.createElement('text', {
            x: 854.111,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#666767",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 339}}

            , "SYSTEM"
            , React.createElement('tspan', {
              x: "1008.57px 1140.34px 1239.63px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 351}}

              , ""
            )
          )
          , React.createElement('path', {
            d: "M0 122.953-37.645 10.171C-7.187 0 24.409 2.23 53.131 16.578L0 122.953Z"        ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 37.969)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 358}}
          )
          , React.createElement('path', {
            d: "m0 112.782-105.425-54.979C-90.577 29.33-68.103 10.169-37.645 0L0 112.782Z"     ,
            style: {
              fill: "#666767",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 58.311)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 368}}
          )
          , React.createElement('path', {
            d: "m0 21.292-114.03 33.687c-9.094-30.794-6.248-60.194 8.605-88.666L0 21.292Z"    ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 241.29)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 378}}
          )
          , React.createElement('path', {
            d: "M0-103.445-58.623 0c-27.936-15.838-46.307-38.961-55.407-69.758L0-103.445Z" ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 490.764)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 388}}
          )
          , React.createElement('path', {
            d: "m0-29.339 53.131-106.375c58.749 29.349 82.582 100.759 53.24 159.5C77.027 82.536 5.618 106.375-53.125 77.032c-2.356-1.173-3.21-1.633-5.498-2.926L0-29.339Z"          ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 342.552)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 398}}
          )
          , React.createElement('path', {
            d: "M-59.585-36.244c-37.049-.004-67.089 30.04-67.094 67.09.003 37.055 30.044 67.093 67.094 67.099 37.056-.007 67.098-30.043 67.098-67.095-.005-37.056-30.037-67.097-67.098-67.094"          ,
            style: {
              fill: "#fff",
              fillRule: "nonzero",
            },
            transform: "scale(2 -2) rotate(62.633 205.105 143.093)"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 408}}
          )
          , React.createElement('path', {
            style: {
              fill: "#666767",
            },
            d: "M808.838 77.111h31.59v31.597h-31.59z" ,
            transform: "matrix(-2 0 0 2 3298.54 204.362)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 416}}
          )
        )
      ) : pathname.includes("/service") || pathname.includes("/openservice") ? (
        React.createElement('svg', {
          x: "0px",
          y: "0px",
          viewBox: "2000 0 232 576"   ,
          xmlns: "http://www.w3.org/2000/svg",
          xmlSpace: "preserve",
          style: {
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeMiterlimit: 1,
            width: "250px",
            height: "70%",
            cursor: "hand",
          },
          onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 425}}

          , React.createElement('text', {
            x: 332.156,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#666",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 441}}

            , "o"
            , React.createElement('tspan', {
              x: "447.691px 566.474px 669.749px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 453}}

              , "pen"
            )
          )
          , React.createElement('text', {
            x: 854.111,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#7fb847",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 460}}

            , "SERVICE"
            , React.createElement('tspan', {
              x: "1008.57px 1140.34px 1239.63px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 472}}

              , ""
            )
          )
          , React.createElement('path', {
            d: "M0 122.953-37.645 10.171C-7.187 0 24.409 2.23 53.131 16.578L0 122.953Z"        ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 37.969)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 479}}
          )
          , React.createElement('path', {
            d: "m0 112.782-105.425-54.979C-90.577 29.33-68.103 10.169-37.645 0L0 112.782Z"     ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 58.311)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 489}}
          )
          , React.createElement('path', {
            d: "m0 21.292-114.03 33.687c-9.094-30.794-6.248-60.194 8.605-88.666L0 21.292Z"    ,
            style: {
              fill: "#7fb847",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 241.29)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 499}}
          )
          , React.createElement('path', {
            d: "M0-103.445-58.623 0c-27.936-15.838-46.307-38.961-55.407-69.758L0-103.445Z" ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 490.764)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 509}}
          )
          , React.createElement('path', {
            d: "m0-29.339 53.131-106.375c58.749 29.349 82.582 100.759 53.24 159.5C77.027 82.536 5.618 106.375-53.125 77.032c-2.356-1.173-3.21-1.633-5.498-2.926L0-29.339Z"          ,
            style: {
              fill: "#b3b3b3",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 342.552)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 519}}
          )
          , React.createElement('path', {
            d: "M-59.585-36.244c-37.049-.004-67.089 30.04-67.094 67.09.003 37.055 30.044 67.093 67.094 67.099 37.056-.007 67.098-30.043 67.098-67.095-.005-37.056-30.037-67.097-67.098-67.094"          ,
            style: {
              fill: "#fff",
              fillRule: "nonzero",
            },
            transform: "scale(2 -2) rotate(62.633 205.105 143.093)"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 529}}
          )
          , React.createElement('path', {
            style: {
              fill: "#7fb847",
            },
            d: "M808.838 77.111h31.59v31.597h-31.59z" ,
            transform: "matrix(-2 0 0 2 3298.54 204.362)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 537}}
          )
        )
      ) : (
        React.createElement('svg', {
          x: "0px",
          y: "0px",
          viewBox: "2000 0 232 576"   ,
          xmlns: "http://www.w3.org/2000/svg",
          xmlSpace: "preserve",
          style: {
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeMiterlimit: 1,
            width: "250px",
            height: "70%",
            cursor: "hand",
          },
          onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 546}}

          , React.createElement('text', {
            x: 332.156,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#666",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 562}}

            , "o"
            , React.createElement('tspan', {
              x: "447.691px 566.474px 669.749px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 574}}

              , "pen"
            )
          )
          , React.createElement('text', {
            x: 854.111,
            y: 210.664,
            style: {
              fontFamily: "'LucidaGrande','Lucida Grande',sans-serif",
              fontWeight: 500,
              fontSize: "214.585px",
              fill: "#666",
            },
            transform: "scale(2)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 581}}

            , "INC"
            , React.createElement('tspan', {
              x: "1008.57px 1140.34px 1239.63px"  ,
              y: "210.664px 210.664px 210.664px"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 593}}

              , ""
            )
          )
          , React.createElement('path', {
            d: "M0 122.953-37.645 10.171C-7.187 0 24.409 2.23 53.131 16.578L0 122.953Z"        ,
            style: {
              fill: "#b2b2b2",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 37.969)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 600}}
          )
          , React.createElement('path', {
            d: "m0 112.782-105.425-54.979C-90.577 29.33-68.103 10.169-37.645 0L0 112.782Z"     ,
            style: {
              fill: "#666767",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 58.311)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 610}}
          )
          , React.createElement('path', {
            d: "m0 21.292-114.03 33.687c-9.094-30.794-6.248-60.194 8.605-88.666L0 21.292Z"    ,
            style: {
              fill: "#7fb847",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 241.29)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 620}}
          )
          , React.createElement('path', {
            d: "M0-103.445-58.623 0c-27.936-15.838-46.307-38.961-55.407-69.758L0-103.445Z" ,
            style: {
              fill: "#d2d532",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 490.764)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 630}}
          )
          , React.createElement('path', {
            d: "m0-29.339 53.131-106.375c58.749 29.349 82.582 100.759 53.24 159.5C77.027 82.536 5.618 106.375-53.125 77.032c-2.356-1.173-3.21-1.633-5.498-2.926L0-29.339Z"          ,
            style: {
              fill: "#4186c6",
              fillRule: "nonzero",
              stroke: "#fff",
              strokeWidth: 6,
            },
            transform: "matrix(2 0 0 2 369.824 342.552)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 640}}
          )
          , React.createElement('path', {
            d: "M-59.585-36.244c-37.049-.004-67.089 30.04-67.094 67.09.003 37.055 30.044 67.093 67.094 67.099 37.056-.007 67.098-30.043 67.098-67.095-.005-37.056-30.037-67.097-67.098-67.094"          ,
            style: {
              fill: "#fff",
              fillRule: "nonzero",
            },
            transform: "scale(2 -2) rotate(62.633 205.105 143.093)"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 650}}
          )
          , React.createElement('path', {
            style: {
              fill: "#b2b2b2",
            },
            d: "M808.838 77.111h31.59v31.597h-31.59z" ,
            transform: "matrix(-2 0 0 2 3298.54 204.362)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 658}}
          )
        )
      )
    )
  );
};
