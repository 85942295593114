import * as styled from "styled-components";

export const CommandBarStyle = styled.createGlobalStyle`
  :root {
    --opendash-commandbar-primary: var(--ant-primary-color);
    --opendash-commandbar-background: rgb(252 252 252);
    --opendash-commandbar-a1: rgba(0 0 0 / 0.05);
    --opendash-commandbar-a2: rgba(0 0 0 / 0.1);
    --opendash-commandbar-foreground: rgb(28 28 29);
    --opendash-commandbar-shadow: 0px 6px 20px rgb(0 0 0 / 20%);

    --opendash-commandbar-unit: 8px;
  }

  .opendash-commandbar--animator {
    max-width: 600px;
    width: 100%;
    background: var(--opendash-commandbar-background);
    color: var(--opendash-commandbar-foreground);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: var(--opendash-commandbar-shadow);
  }
  .opendash-commandbar--search {
    padding: 12px 16px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    background: var(--opendash-commandbar-background);
    color: var(--opendash-commandbar-foreground);
  }
  .opendash-commandbar--group {
    padding: 8px 16px;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .opendash-commandbar--result {
    padding: 12px 16px;
    background: transparent;
    border-left: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .opendash-commandbar--result--active {
    background: var(--opendash-commandbar-a1);
    border-left: 2px solid var(--opendash-commandbar-primary);
  }

  .opendash-commandbar--result-container {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
  }

  .opendash-commandbar--result-content {
    display: flex;
    flex-direction: column;
  }

  .opendash-commandbar--result-title {
  }

  .opendash-commandbar--result-description {
    font-size: 12px;
  }

  .opendash-commandbar--result-icon {
  }

  .opendash-commandbar--result-parents-container {
  }

  .opendash-commandbar--result-parents-title {
    opacity: 0.5;
    margin-right: 8px;
  }

  .opendash-commandbar--result-parents-divider {
    margin-right: 8px;
  }

  .opendash-commandbar--result-keys {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
  }
  .opendash-commandbar--result-key {
    padding: 4px 6px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
  }
`;
