import { useRegisterActions } from "kbar";
import { observer } from "mobx-react-lite";
import { $framework } from "../../../exports";

export const CommandBarActions = observer(function CommandBarActions() {
  useRegisterActions($framework.commands.actions, [
    $framework.commands.actions.length,
  ]);
  return null;
});
