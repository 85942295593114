import Parse from "parse";
import clientselector from "./config";

Parse.initialize(clientselector.getParseAppId());
Parse.serverURL = clientselector.getParseHost();

export const ParseAdminConfig = [
  {
    key: "roles",
    className: "_Role",
    relation: ["name"],
    columns: ["name", "users", "createdAt", "updatedAt"],
    create_fields: ["name"],
    edit_fields: ["name"],
  },

  {
    key: "OWPlcDevice",
    className: "OWPlcDevice",
    relation: ["name"],
    columns: [
      "name",
      "connectionString",
      "updatedAt",
      "enabled",
      "interval",
      "options",
      "extraOptions",
    ],
    create_fields: [
      "name",
      "connectionString",
      "enabled",
      "interval",
      "extraOptions",
      "options",
    ],
    edit_fields: [
      "name",
      "connectionString",
      "enabled",
      "interval",
      "extraOptions",
      "options",
    ],
  },
  {
    key: "OWPlcItem",
    className: "OWPlcItem",
    relation: ["label"],
    asSelect: {
      type: [
        ["Number", "Zahl"],
        ["String", "Zeichenkette"],
        ["Boolean", "Wahrheitswert"],
        ["Geo", "Geoinformation"],
        ["Object", "Datenstruktur"],
      ],
    },
    columns: [
      "label",
      "enabled",
      "OWSource",
      "address",
      "type",
      "updatedAt",
      "onChange",
      "DeviceID",
      "OWSource",
    ],
    create_fields: [
      "label",
      "enabled",
      "onChange",
      "address",
      "type",
      "DeviceID",
      "OWSource",
    ],
    edit_fields: [
      "label",
      "enabled",
      "onChange",
      "address",
      "type",
      "DeviceID",
      "OWSource",
    ],
  },
  {
    key: "users",
    className: "_User",
    relation: ["username"],
    columns: [
      "name",
      "username",
      "email",
      "tenant",
      "tenantAdmin",
      "tenantVerified",
    ],
    create_fields: [
      "name",
      "username",
      "email",
      "password",
      "tenant",
      "tenantAdmin",
      "tenantVerified",
    ],
    edit_fields: [
      "name",
      "username",
      "email",
      "password",
      "tenant",
      "tenantAdmin",
      "tenantVerified",
    ],
  },
  {
    key: "tenant",
    className: "OD3_Tenant",
    relation: ["label"],
    columns: ["label"],
    create_fields: ["label"],
    edit_fields: ["label"],
  },
  {
    key: "sources",
    className: "OD3_Sources",
    relation: ["name", "tag"],
    columns: ["name", "tag", "meta"],
    create_fields: ["name", "tag", "meta"],
    edit_fields: ["name", "tag", "meta"],
  },

  {
    key: "nav-groups",
    className: "OD3NavigationGroup",
    relation: ["label"],
    columns: ["label", "icon", "order"],
    create_fields: ["label", "icon", "order"],
    edit_fields: ["label", "icon", "order"],
  },
  {
    key: "nav-items",
    className: "OD3NavigationItem",
    relation: ["label"],
    columns: [
      "group",
      "label",
      "place",
      "order",
      "routeCondition",
      "link",
      "activeCondition",
    ],
    create_fields: [
      "group",
      "label",
      "place",
      "order",
      "routeCondition",
      "link",
      "activeCondition",
    ],
    edit_fields: [
      "group",
      "label",
      "place",
      "order",
      "routeCondition",
      "link",
      "activeCondition",
    ],
  },
];
