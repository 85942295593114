const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\ErrorBoundary.layout.tsx";import * as React from "react";

import styled from "styled-components";

import { Button, Divider } from "antd";
import { useTranslation } from "..";

const Container = styled.div`
  padding: 24px;
`;

const Message = styled.div``;

const Actions = styled.div`
  & > button {
    margin-right: 24px;
  }
`;

export const ErrorLayout


 = ({ children, reset }) => {
  const t = useTranslation();

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      , React.createElement(Message, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, children)
      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )
      , React.createElement(Actions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        , React.createElement(Button, {
          type: "primary",
          children: t("opendash:error.reload"),
          onClick: (e) => {
            reset(false);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        )
        , React.createElement(Button, {
          children: t("opendash:error.reset"),
          onClick: (e) => {
            reset(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        )
      )
    )
  );
};
