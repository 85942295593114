






import { BaseService } from "../classes/BaseService";

import { NavigationAdapterContext } from "./NavigationAdapterContext";

export class NavigationService extends BaseService {
  
  
  

   __init() {this.dynamicNavigationGroups = new Set()}
   __init2() {this.dynamicNavigationItems = new Set()}

  constructor(framework) {
    super({
      initialState: {},
    });NavigationService.prototype.__init.call(this);NavigationService.prototype.__init2.call(this);;

    this.framework = framework;
    this.context = new NavigationAdapterContext(this, framework);
  }

   async init(adapter) {
    this.adapter = adapter;
    this.initAdapter(adapter, this.context);
  }

  getGroups(place) {
    return Array.from(this.dynamicNavigationGroups.values());
  }

  getItems(place) {
    return Array.from(this.dynamicNavigationItems.values());
  }

  registerDynamicNavigationGroup(group) {
    this.dynamicNavigationGroups.add(group);
    super.notifySubscribers();

    return () => {
      this.dynamicNavigationGroups.delete(group);
      super.notifySubscribers();
    };
  }

  registerDynamicNavigationItem(item) {
    this.dynamicNavigationItems.add(item);
    super.notifySubscribers();

    return () => {
      this.dynamicNavigationItems.delete(item);
      super.notifySubscribers();
    };
  }
}
