const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\_layout\\HeaderNavigation.tsx";import { Icon } from "@opendash/icons";
import { useNavigate } from "@opendash/router";
import { HeaderMenuItem as MenuItem } from "@opendash/ui";
import { Dropdown, Spin } from "antd";

import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import {
  OpenDashLogo,
  useCurrentUser,
  useOpenDashApp,
  useOpenDashServices,
  useTranslation,
} from "../..";
import { Hamburger } from "./Hamburger";
import { HeaderSourcePicker, breakpoint } from "./HeaderSourcePicker";
import * as Layout from "./components";

const MainMenu = styled.div`
  position: relative;
  display: flex;
  /* padding: 0 24px; */
  padding: 0;
  height: var(--opendash-header-row-height);
`;

const MainMenuLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const MainMenuRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MenuRef = styled.div`
  float: left;
  height: var(--opendash-header-row-height);
  line-height: var(--opendash-header-row-height);
`;

export const HeaderNavigation = observer(function HeaderNavigation() {
  const user = useCurrentUser();
  const app = useOpenDashApp();
  const t = useTranslation();
  const navigate = useNavigate();
  const services = useOpenDashServices();
  let menuItems = [];

  if (user) {
    menuItems = [
      {
        key: "1",
        onClick: () => {},
        label: (
          React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}, t("opendash:auth.greeting_dropdown") + " " + user.username)
        ) || React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, user.name) || React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, user.email),
        style: { padding: "12px 24px" },
      },
      {
        key: "2",
        onClick: () => app.ui2.showLanguageDialog(),
        label: t("opendash:account.language.label"),
        icon: React.createElement(Icon, { icon: "fa:globe", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}} ),
        disabled: app.ui2.languageDialogDisabled,
        style: { padding: "12px 24px" },
      },
      {
        key: "3",
        onClick: () => app.ui2.showResetPWDialog(),
        label: t("opendash:account.resetpw.label"),
        icon: React.createElement(Icon, { icon: "fa:lock", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}} ),
        style: { padding: "12px 24px" },
      },
      {
        key: "4",
        onClick: () => services.UserService.logout(),
        label: t("opendash:auth.logout"),
        icon: React.createElement(Icon, { icon: "fa:sign-out", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} ),
        style: { padding: "12px 24px" },
      },
    ];
  } else {
    menuItems = [
      { key: "1", icon: React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}} ), onClick: () => navigate("/auth/login") },
    ];
  }

  return (
    React.createElement(Layout.HeaderRow, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
      , React.createElement(MainMenu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
        , user && (
          React.createElement(MainMenuLeft, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
            , React.createElement(MenuRef, {
              key: "headerBeforeMenuLeft",
              ref: app._refs.headerBeforeMenuLeft, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
            )
            , React.createElement(MenuRef, { key: "headerAfterLogo1", ref: app._refs.headerAfterLogo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )

            , app.ui2.sidebar.available && (
              React.createElement(MenuItem, {
                key: "apps",
                onClick: () => {
                  app.ui2.updateSidebar({ open: !app.ui2.sidebar.open });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}

                , React.createElement(Hamburger, {
                  open: app.ui2.sidebar.open,
                  setOpen: (isOpen) => {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
                )
              )
            )

            , React.createElement(MenuRef, { key: "headerBeforeLogo", ref: app._refs.headerBeforeLogo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}} )
            , React.createElement(OpenDashLogo, {
              style: {
                display: "block",
                height: "100%",
                width: "auto",
                padding: "8px",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
            )
            , React.createElement(MenuRef, { key: "headerAfterLogo2", ref: app._refs.headerBeforeLogo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}} )
          )
        )

        , user && (
          React.createElement(MainMenuRight, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
            , !app.ui.disableHeaderSourcePicker &&
              app.ui2.headerWidth >= breakpoint && (
                React.createElement(MenuItem, { key: "source", __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
                  , React.createElement(HeaderSourcePicker, {
                    mobile: false,
                    style: { width: "20vw" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
                  )
                )
              )
            , React.createElement(MenuRef, {
              key: "headerAfterMenuLeft",
              ref: app._refs.headerAfterMenuLeft, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
            )
            , React.createElement(MenuRef, {
              key: "headerBeforeMenuRight",
              ref: app._refs.headerBeforeMenuRight, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
            )
            , React.createElement(Dropdown, {
              open: app.ui2.userDialogOpen,
              onOpenChange: (open) => {
                if (open) {
                  app.ui2.showUserDialog();
                } else {
                  app.ui2.hideUserDialog();
                }
              },
              trigger: ["click"],
              placement: "bottomRight",
              menu: {
                items: menuItems,
              },
              children: 
                React.createElement(MenuItem, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
                  , React.createElement(Icon, { icon: "fa:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}} )
                )
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}
            )
            , React.createElement(MenuRef, {
              key: "headerAfterMenuRight",
              ref: app._refs.headerAfterMenuRight, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
            )
          )
        )
      )
    )
  );
});
