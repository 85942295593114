const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\AdminLayout.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";

import { Layout } from "antd";

import styled from "styled-components";
import {
  AppSidebar,
  Boundary,
  Loading,
  NavigationMenu,
  useElementSize,
  useNavigation,
} from "..";

const Container = styled.div`
  background: white;
  width: 100%;
  min-height: 100%;

  padding: ${(p) => (p.padding ? "24px" : "0")};
`;
const Sidebar = styled.div``;
const Content = styled.div``;









export const AdminLayout = React.memo(function AdminLayout({
  children,
  contentPadding = false,
  siderStyle,
  loading,
  contentWidth,
  footerChildren,
}) {
  const { width } = useElementSize({ current: _optionalChain([window, 'optionalAccess', _ => _.document, 'optionalAccess', _2 => _2.body]) }, 100);
  const [groups, items, activeItems] = useNavigation("sidebar");

  const isMobile = width < 1200;

  if (isMobile) {
    return (
      React.createElement(Container, { padding: contentPadding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        , React.createElement(AppSidebar, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
        , React.createElement(Boundary, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, children)
      )
    );
  }

  return (
    React.createElement(Layout, { style: { background: "rgb(240, 242, 245)", overflow: "hidden" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      , isMobile && React.createElement(AppSidebar, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} )
      , !isMobile && (groups.length > 0 || items.length > 0) ? (
        React.createElement(Layout.Sider, { width: 300, style: { background: "#fff", ...siderStyle }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
          , React.createElement(NavigationMenu, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )
        )
      ) : null
      , React.createElement(Layout, { style: { overflow: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        , React.createElement(Layout.Content, {
          style: {
            margin: "24px",
            width: contentWidth ? contentWidth : "auto",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}

          , loading ? React.createElement(Loading, { message: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}} ) : React.createElement(Boundary, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, children)
        )
        , footerChildren ? (
          React.createElement(Layout.Footer, {
            style: { margin: "24px", background: "#fff" },
            children: footerChildren, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
          )
        ) : undefined
      )
    )
  );
});
