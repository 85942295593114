

export class UserAdapterContext {
  
  

  constructor(service, app) {
    this.service = service;
    this.app = app;
  }

  setLoading(value) {
    this.service.setLoading(value);
  }

  setValidated(value) {
    this.service.store.update((draft) => {
      draft.validated = value;
    });
  }

  setOffline(value) {
    this.service.store.update((draft) => {
      draft.offline = value;
    });
  }

  setCurrentUser(user) {
    this.service.store.update((draft) => {
      draft.currentUser = user;
    });
  }

  setConfig(config) {
    this.service.store.update((draft) => {
      draft.config = config;
    });
  }

  setPermissions(permissions) {
    this.service.store.update((draft) => {
      draft.permissions = permissions;
    });
  }
}
