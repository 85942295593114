const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\LinkedSourcePicker.tsx";import * as React from "react";

import { SourcePicker, useSource } from "..";






export function LinkedSourcePicker({ style, asButton }) {
  const [source, setSource, sources] = useSource();

  if (!sources || sources.length === 0) {
    return null;
  }

  return (
    React.createElement(SourcePicker, {
      value: source,
      onChange: (source) => setSource(source.id),
      style: style,
      asButton: asButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    )
  );
}
