// use * as styled to import  everything so prettier will format createGlobalStyle
import * as styled from "styled-components";

export const GlobalStyle = styled.createGlobalStyle`
  :root {
    @font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,;
    @font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,;
    --opendash-header-row-height: 46px;
    --opendash-primary-color: #666767;

    --opendash-logocolor-darkgray: #666767;

    --opendash-color-white: #fff;
    --opendash-color-black: #000;
    --opendash-color-gray: gray;
    --opendash-color-lightblack: #303030;
    --opendash-color-lightgray: #fafafa;
    --opendash-color-darkgray: #999;
    --opendash-color-green: green;
    --opendash-color-red: red;
    --opendash-color-blue: #4186c6;
    --opendash-color-yellow: #d2d532;
    --opendash-color-slider-good: #1e8c00;
    --opendash-color-slider-middle: #ff9900;
    --opendash-color-slider-bad: #8c0000;

    --opendash-padding-10: 10px;
  }

  html,
  .ant-popover {
    white-space: pre-line;
  }

  .od-page-header {
    margin-bottom: 16px;
    padding: 24px;
    background: white;
  }

  .od-page-main {
    width: 100%;
    padding: 24px;
    background: white;
  }

  a,
  .ant-btn-link,
  .ant-typography a {
    color: var(--ant-primary-color);

    &:hover,
    &:not(:disabled):hover {
      color: var(--ant-primary-color-hover);
    }
  }

  .depth-1 {
    background-color: #fdfdfd;
  }

  .depth-0 {
    background-color: #f9f9f9;
  }

  @media only screen and (max-width: 620px) {
    :root {
      --opendash-padding-10: 0px;
    }

    .od-page-header {
      margin-bottom: 0px;
      padding: 15px;
      padding-bottom: 0px;
      background: white;
    }

    .od-page-main {
      width: 100%;
      padding: 15px;
      background: white;
    }
  }

  @media print {
    #opendash-header {
      position: relative;
    }

    #opendash-header > div > div > div:nth-child(2) {
      display: none;
    }
    #opendash-layout {
      padding-top: 0;
    }
    #opendash-footer {
      display: none;
    }
  }
`;
