const _jsxFileName = "C:\\Users\\NicoVitt\\Code\\opendash_development_root\\opendash\\libs\\core\\src\\components\\OpenDashFrontpage.tsx";import * as React from "react";

import { Navigate } from "@opendash/router";
import {
  Container,
  ContainerInner,
  Element,
  FrontPageHero,
  HeroElement,
} from "./OpenDashFrontpage.layout";

import {
  $framework,

  useNavigation,
  useTranslation,
} from "..";

export const OpenDashFrontpage = React.memo(function OpenDashFrontpage() {
  const t = useTranslation();
  const [, items] = useNavigation("frontpage");

  const orderSortFunction = (
    a,
    b
  ) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  const elements = React.useMemo(
    () =>
      items
        .sort(orderSortFunction)
        .filter((item) => item.link)
        .filter((item) => item.icon)
        .filter((item) => item.color)
        .map((item) => ({
          ...item,
          onClick: () => {
            if (item.link) {
              if (typeof item.link === "string") {
                if (
                  item.link.startsWith("https://") ||
                  item.link.startsWith("http://") ||
                  item.link.startsWith("tel:") ||
                  item.link.startsWith("mailto:")
                ) {
                  window.open(item.link);
                } else {
                  $framework.router.navigate(item.link);
                }
              } else if (typeof item.link === "function") {
                (item.link )();
              }
            }
          },
        })),
    [items]
  );

  if (elements.length === 1) {
    if (elements[0].link && typeof elements[0].link === "string") {
      return React.createElement(Navigate, { to: elements[0].link, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} );
    }
  }

  /**
   * Only elements with an order higher than 100 will be displayed.
   * @param element An element of type NavigationItemInterface
   * @returns The element as a card for the frontpage
   */
  function getHeroElement(element) {
    const elementcount = elements.filter((parentelement) => {
      if (typeof parentelement.group !== "undefined") {
        return parentelement.group === element.group;
      }
      return false;
    });

    if (elementcount.length > 1 && element.order > 100) {
      return React.createElement(HeroElement, { key: element.id, ...element, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} );
    }
    return;
  }

  function countHeroElements() {
    const elementcount = elements.map((element) => {
      return elements.filter((parentelement) => {
        if (typeof parentelement.group !== "undefined") {
          return parentelement.group === element.group && element.order > 100;
        }
        return false;
      });
    });

    return elementcount.some((value) => value.length > 0);
  }

  function getElement(e) {
    //Exclude child elements
    //Those will be put to the hero
    const groupelements = elements
      .filter((elem) => elem.group === e.group)
      .sort(orderSortFunction);

    if (groupelements.length > 0 && groupelements[0].id !== e.id) {
      return null;
    }

    //Get top level elements
    return React.createElement(Element, { key: e.id, ...e, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}} );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Container, { style: { margin: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
        , React.createElement(ContainerInner, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
          , elements.map((e) => getElement(e))

          , elements.length === 0 && t("opendash:frontpage.empty")
        )
        , countHeroElements() ? (
          React.createElement(FrontPageHero, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
            , elements.map((e) => getHeroElement(e))
          )
        ) : null
      )
    )
  );
});
