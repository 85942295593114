 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }

import { BaseService } from "../classes/BaseService";

import { StorageAdapterContext } from "./StorageAdapterContext";

export class StorageService extends BaseService {
  
  
  

   __init() {this.storage = new Map()}
   __init2() {this.cache = new Map()}

  constructor(framework) {
    super({
      initialState: {},
    });StorageService.prototype.__init.call(this);StorageService.prototype.__init2.call(this);;

    this.framework = framework;
    this.context = new StorageAdapterContext(this.storage, this);
  }

   async init(adapter) {
    this.adapter = adapter;
    this.initAdapter(adapter, this.context);
  }

   async get(key) {
    return this._getSync(key);
  }

   async set(
    key,
    value,
    options
  ) {
    const nextValue = _optionalChain([options, 'optionalAccess', _ => _.encode])
      ? options.encode(value)
      : JSON.stringify(value);

    if (this.storage.has(key) && this.storage.get(key) === nextValue) {
      return;
    }

    if (!this.adapter) {
      return void console.warn(
        "StorageService.set was called before an adapter was supplied",
        key,
        value
      );
    }

    return await this.adapter.set(key, nextValue);
  }

   async delete(key) {
    return await this.adapter.delete(key);
  }

   async keys() {
    return this._keysSync();
  }

   async clear() {
    return await this.adapter.clear();
  }

   _getSync(key, options) {
    if (this.cache.has(key)) {
      return this.cache.get(key);
    }

    if (this.storage.has(key)) {
      const cache = _optionalChain([options, 'optionalAccess', _2 => _2.decode])
        ? options.decode(this.storage.get(key))
        : JSON.parse(this.storage.get(key));

      this.cache.set(key, cache);

      return cache;
    }

    return undefined;
  }

   _keysSync() {
    return Array.from(this.storage.keys());
  }

   _clearCache(key) {
    this.cache.delete(key);
  }
}
