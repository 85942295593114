







export class NavigationAdapterContext {
  
  

  constructor(service, framework) {
    this.service = service;
    this.framework = framework;
  }

  setLoading(value) {
    this.service.setLoading(value);
  }

  setNavigationGroups(items) {
    this.framework.state.update((draft) => {
      draft.navigation.userNavigationGroups = items;
      draft.navigation.userNavigationGroups.sort((a, b) => a.order - b.order);
    });
  }

  updateNavigationGroup(id, item) {
    this.framework.state.update((draft) => {
      const item = draft.navigation.userNavigationGroups.find(
        (i) => i.id === id
      );

      // create
      if (!item && item) {
        draft.navigation.userNavigationGroups.push(item);
      }

      // delete
      if (item && !item) {
        draft.navigation.userNavigationGroups =
          draft.navigation.userNavigationGroups.filter((i) => i !== item);
      }

      // update
      if (item) {
        Object.assign(item, item);
      }

      draft.navigation.userNavigationGroups.sort((a, b) => a.order - b.order);
    });
  }

  setNavigationItems(items) {
    this.framework.state.update((draft) => {
      draft.navigation.userNavigationItems = items;
      draft.navigation.userNavigationItems.sort((a, b) => a.order - b.order);
    });
  }

  updateNavigationItem(id, item) {
    this.framework.state.update((draft) => {
      const item = draft.navigation.userNavigationItems.find(
        (i) => i.id === id
      );

      // create
      if (!item && item) {
        draft.navigation.userNavigationItems.push(item);
      }

      // delete
      if (item && !item) {
        draft.navigation.userNavigationItems =
          draft.navigation.userNavigationItems.filter((i) => i !== item);
      }

      // update
      if (item) {
        Object.assign(item, item);
      }

      draft.navigation.userNavigationItems.sort((a, b) => a.order - b.order);
    });
  }
}
