import { getCurrentLanguageSync } from "@opendash/i18n";
import { timeSelectUnit } from "..";

// @ts-ignore // TODO

export function formatRelativeDates(
  from,
  to = Date.now()
) {
  const rtf = Intl.RelativeTimeFormat
    ? new Intl.RelativeTimeFormat(getCurrentLanguageSync())
    : null;

  if (!from) {
    console.warn("formatRelativeDates requires a 'from' parameter");
    return "-";
  }

  if (!rtf) {
    return Math.round(Math.abs(from.valueOf() - to.valueOf()) / 1000) + "s";
  }

  return rtf.format(...timeSelectUnit(from, to));
}
